<template>
  <CCard>
    <CCardHeader>
      <h3>Eliminar Parametro de la Aplicación</h3>
    </CCardHeader>

    <CCardBody>
      <div class="form-group">
        <label>Codigo:</label>
        <p>{{ registro.id }}</p>
      </div>

      <div class="form-group">
        <label>Etiqueta:</label>
        <p>{{ registro.etiqueta }}</p>
      </div>
      <div class="form-group">
        <label>Valor:</label>
        <p>{{ registro.valor }}</p>
      </div>

      <div class="form-group">
        <button type="button" class="btn btn-danger" @click="deleted()">
          <span class="cil-trash btn-icon mr-2"></span>
          Eliminar
        </button>

        <router-link to="/configuracionApp/List">
          <CButton class="ml-2" color="secondary">
            <span class="cil-x-circle btn-icon mr-2"></span>
            Cancelar
          </CButton>
        </router-link>
      </div>
    </CCardBody>
  </CCard>
</template>
<script>
import httpService from "@/services/httpService"

export default {
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: '',
      registro: {
        id: '',
        etiqueta: '',
        valor: '',
      }
    };
  },

  methods: {
    async getRegistro() {

      this.id = this.$route.params.id;
      if (this.id !== "0") {
        let url = "app-configuraciones/" + this.id;
        let response = await httpService.get(url);
        let respuesta = response.data;
        this.registro = respuesta.registros[0];

      }
    },
    async deleted() {

      let url = "app-configuraciones/" + this.id;
      let response = await httpService.delete(url);


      this.$vToastify.success("Operación realizada con éxito", "Éxito");
      this.$router.back()
    }
  },

  mounted() {
    this.getRegistro();
  }
};
</script>